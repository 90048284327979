import React from 'react'
import PropTypes from 'prop-types'

import { Header, Footer } from './'

const Layout = ({ initialBackgroundColor, headerTitle, headerSub, topHeight, pageNav, bgImg, children }) => {

  return (
    <>
      <div className="bg-gray-800 page__wrapper">
        <Header headerTitle={headerTitle} headerSub={headerSub} topHeight={topHeight} pageNav={pageNav} bgImg={bgImg} />
        <main className={'relative z-10 font-secondary p-6 pb-24 mx-auto md:pb-32 lg:pb-40 text-lg font-light ' + initialBackgroundColor }>{children}</main>
        <Footer pageNav={pageNav} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  initialBackgroundColor: PropTypes.string,
  headerTitle: PropTypes.string,
  bgImg: PropTypes.string,
  topHeight: PropTypes.string
}

// Specifies the default values for props:
Layout.defaultProps = {
  initialBackgroundColor: 'bg-white',
  topHeight: `h-1/2 md:h-460`,
  bgImg: 'none'
};

export default Layout